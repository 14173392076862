.App {
  padding-top: 100px;
  padding-bottom: 80px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.main {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;

  & > * {
    flex: 1;
    margin: 2rem;
    box-shadow: 0 0 2px 0 grey;
    padding: 1rem;
  }
}

.border-danger {
  border-color: darkred;
}


.text-danger {
  color: darkred;

  & > * {
    color: darkred;
  }
}

header {
  position: fixed;
  left: 0;
  top: 0;
  height: 100px;
  background-color: #4daf47;
  width: 100vw;
  text-align: center;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  color: white;

  nav {
    display: none;
    width: 100%;
  }
}

@keyframes refreshing {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(260deg);
  }
}

.refreshing {
  display: inline-block;
  animation: 1s linear refreshing;
}

.do-refresh {
  position: absolute;
  display: block;
  right: 1rem;
  top: 1rem;
  padding: 0.5rem;
}

.modal {
  display: block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 20vh 20vw;

  .content {
    background-color: white;
    max-width: 60vw;
    text-align: center;
    padding: 1rem 0;
  }
}

.score {

}

table {
  width: 100%;

  thead {
    tr {
      th {
        text-align: left;
      }
    }
  }

  tr {
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
    margin: 0.25rem 0;
    &.bold {
      font-weight: bold;
    }

    &:hover {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
    }

    &.round {
      box-shadow: none;

      td {
        padding-bottom: 0;
        font-size: 80%;
      }
    }

    td, th {
      padding: 5px;
    }

    .game-name, .game-name-actions, .score {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
    .score{
      align-items: center;
    }
    .vs{
      text-align: center;
    }
  }
}

@media (max-width: 600px) {
  .App {
    padding-top: 120px;
    padding-bottom: 80px;
  }

  .main {
    display: block;

    & > * {
      display: none;
      margin: 0.5rem;
      padding: 0.5rem;
    }

    & > .active {
      display: block;
    }
  }

  header {
    height: 120px;

    nav {
      display: block;

      button {
        border: none;
        border-bottom: solid thin rgba(255, 255, 255, 60%);
        background-color: transparent;
        padding: 0 1rem;
        margin-left: 1rem;
        font-size: 100%;
        color: rgba(255, 255, 255, 60%);

        &:hover, &.active {
          border-bottom: solid thin white;
          color: white;
        }
      }
    }
  }

  table {

    tr {
      &.round {
        text-align: center;
      }


      .game-name-actions {
        display: none;
      }
    }
  }
}

.hidden {
  display: none;
  visibility: hidden;
}

.gamesKO {
  .tree {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    & > div.viertl-game {
      margin: 1rem;
      padding: 1rem;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.4);
      flex: 1;
      display: flex;
      align-items: stretch;
      justify-content: space-between;

      &.finale{
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.8);

      }

      .teams, .score {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}


.footer{
  position: fixed;
  bottom: 0;
  width: 100vw;
  left: 0;
  background-color: #4daf47;
  color: white;
  padding: 0.5rem;
  text-align: center;
  a{
    color: white;
    text-decoration: none;
  }
}
