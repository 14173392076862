.App {
  padding-top: 100px;
  padding-bottom: 80px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.main {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}
.main > * {
  flex: 1;
  margin: 2rem;
  box-shadow: 0 0 2px 0 grey;
  padding: 1rem;
}

.border-danger {
  border-color: darkred;
}

.text-danger {
  color: darkred;
}
.text-danger > * {
  color: darkred;
}

header {
  position: fixed;
  left: 0;
  top: 0;
  height: 100px;
  background-color: #4daf47;
  width: 100vw;
  text-align: center;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  color: white;
}
header nav {
  display: none;
  width: 100%;
}

@keyframes refreshing {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(260deg);
  }
}
.refreshing {
  display: inline-block;
  animation: 1s linear refreshing;
}

.do-refresh {
  position: absolute;
  display: block;
  right: 1rem;
  top: 1rem;
  padding: 0.5rem;
}

.modal {
  display: block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 20vh 20vw;
}
.modal .content {
  background-color: white;
  max-width: 60vw;
  text-align: center;
  padding: 1rem 0;
}

table {
  width: 100%;
}
table thead tr th {
  text-align: left;
}
table tr {
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
  margin: 0.25rem 0;
}
table tr.bold {
  font-weight: bold;
}
table tr:hover {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
}
table tr.round {
  box-shadow: none;
}
table tr.round td {
  padding-bottom: 0;
  font-size: 80%;
}
table tr td, table tr th {
  padding: 5px;
}
table tr .game-name, table tr .game-name-actions, table tr .score {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
table tr .score {
  align-items: center;
}
table tr .vs {
  text-align: center;
}

@media (max-width: 600px) {
  .App {
    padding-top: 120px;
    padding-bottom: 80px;
  }

  .main {
    display: block;
  }
  .main > * {
    display: none;
    margin: 0.5rem;
    padding: 0.5rem;
  }
  .main > .active {
    display: block;
  }

  header {
    height: 120px;
  }
  header nav {
    display: block;
  }
  header nav button {
    border: none;
    border-bottom: solid thin rgba(255, 255, 255, 0.6);
    background-color: transparent;
    padding: 0 1rem;
    margin-left: 1rem;
    font-size: 100%;
    color: rgba(255, 255, 255, 0.6);
  }
  header nav button:hover, header nav button.active {
    border-bottom: solid thin white;
    color: white;
  }

  table tr.round {
    text-align: center;
  }
  table tr .game-name-actions {
    display: none;
  }
}
.hidden {
  display: none;
  visibility: hidden;
}

.gamesKO .tree {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.gamesKO .tree > div.viertl-game {
  margin: 1rem;
  padding: 1rem;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.4);
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.gamesKO .tree > div.viertl-game.finale {
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.8);
}
.gamesKO .tree > div.viertl-game .teams, .gamesKO .tree > div.viertl-game .score {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100vw;
  left: 0;
  background-color: #4daf47;
  color: white;
  padding: 0.5rem;
  text-align: center;
}
.footer a {
  color: white;
  text-decoration: none;
}

